import React, { useState, useEffect } from 'react';
import {
  Button,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  useToast,
  Text,
} from '@chakra-ui/react';
import { post } from 'axios';

const ImageModal = ({ onModalClose, isModalOpen, onModalOpen }) => {
  const [, setFileError] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const toast = useToast();

  const uploadImage = async () => {
    if (
      !(
        selectedFile.type === 'image/png' ||
        selectedFile.type === 'image/jpeg' ||
        selectedFile.type === 'image/jpg'
      )
    ) {
      setFileError({
        message: 'Supported image files are only .png, .jpg, .jpeg',
      });
      toast({
        title: 'File Type Error',
        description: 'Supported image files are only .png, .jpg, .jpeg',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom-left',
      });
      console.info('file type err');
    } else if (selectedFile.size > 5000000) {
      setFileError({ message: 'File size should be less than 5mb' });
      toast({
        title: 'File Size Error',
        description: 'File size should be less than 5mb',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom-left',
      });
    } else {
      setFileError(false);
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        await post(
          'https://api.yearbook.bp-gc.in/api/user/image',
          formData,
          {
            headers: {
              token: localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        toast({
          title: <Text color="black"> Image Uploaded </Text>,
          description: (
            <Text color="black">Your Image Was Successfully Uploaded</Text>
          ),
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
        onModalClose();
      } catch (error) {
        console.info(error);
      }
    }
  };

  return (
    <>
      <Modal
        isCentered
        onClose={onModalClose}
        isOpen={isModalOpen}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={'3xl'}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent
          borderWidth={'4px'}
          borderColor={'red.400'}
          borderRadius={20}
        >
          <ModalHeader>Upload your profile image</ModalHeader>
          <ModalBody>
            <ImageUpload
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onModalClose}>
              Close
            </Button>
            <Button colorScheme="red" onClick={uploadImage}>
              Upload Image
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ImageUpload = ({ selectedFile, setSelectedFile }) => {
  // const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <Button onClick={() => document.getElementById('choose-profile').click()}>
        {/* <Icon as={FaFileUpload} margin={0.5} /> */}
        <span color="black">
          {' '}
          {selectedFile ? 'Change Image' : 'Upload Image'}
        </span>
      </Button>
      <Text as="em" fontSize={'0.85rem'}>
        {' '}(Max 5mb limit)
      </Text>
      <input type="file" onChange={onSelectFile} id="choose-profile" hidden />
      <div className="img-preview-contain">
        {selectedFile && (
          <img src={preview} alt="preview" className="preview-img" />
        )}
      </div>
    </div>
  );
};
export default ImageModal;
