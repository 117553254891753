import { useState, useEffect } from 'react';
import {
  Stack,
  Text,
  Container,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  Button,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Flex,
  FormErrorMessage,
  Modal,
  FormControl,
  FormLabel,
  Image,
  Input,
  Center,
  Icon,
  Box,
  useToast
} from '@chakra-ui/react';
import { FaFileUpload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { post } from 'axios';
import '../index.css';
import { isEmail, isMobilePhone } from 'validator';
import ARC from '../assets/img/ARC.png';
import DEVSOC from '../assets/img/DEVSOC.png';
import M from '../assets/img/Union.png';
import { Buffer } from 'buffer';

const Landing = () => {
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [emailError, setEmailError] = useState(false);
  const [aboutError, setAboutError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [personalEmail, setPersonalEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [about, setAbout] = useState(null);
  const [preview, setPreview] = useState(null);

  const toast = useToast();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  async function submitDetails() {
    if (personalEmail && mobile && about && selectedFile) {
      if (!isMobilePhone(mobile))
        setMobileError({ message: 'Mobile number must be a number' });
      else setMobileError(false);
      if (isEmail(personalEmail)) setEmailError(false);
      else setEmailError({ message: 'Email is not valid' });
      if (
        !(
          selectedFile.type === 'image/png' ||
          selectedFile.type === 'image/jpeg' ||
          selectedFile.type === 'image/jpg'
        )
      ) {
        setFileError({
          message: 'Supported image files are only .png, .jpg, .jpeg',
        });
      } else if (selectedFile.size > 5000000) {
        setFileError({ message: 'File size should be less than 5mb' });
      } else setFileError(false);
      if (about.length > 100) {
        setAboutError({ message: 'Should be less than 100 characters' });
      } else {
        setAboutError(false);
      }
      if (!emailError && !aboutError && !fileError && !mobileError) {
        try {
          await post(
            'https://api.yearbook.bp-gc.in/api/user/about',
            {
              personalEmail,
              mobile,
              about,
            },
            {
              headers: {
                token,
                'Content-Type': 'application/json',
              },
            }
          );
          const formData = new FormData();
          formData.append('file', selectedFile);
          const res = await post(
            'https://api.yearbook.bp-gc.in/api/user/image',
            formData,
            {
              headers: {
                token,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          const { newToken } = res.data;
          localStorage.setItem('token', newToken);
          navigate(
            '/user/' +
              JSON.parse(Buffer.from(newToken.split('.')[1], 'base64').toString('utf8')).email.split('@')[0]
          );
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      if (!mobile) {
        setMobileError({ message: 'Please enter your mobile number' });
      } else setMobileError(false);
      if (!about) {
        setAboutError({ message: 'Please enter a quote about yourself' });
      } else setAboutError(false);
      if (!selectedFile) {
        setFileError({ message: 'Please select a file' });
      } else setFileError(false);
      if (!personalEmail) {
        setEmailError({ message: 'Please enter your email' });
      } else setEmailError(false);
    }
  }

  return (
    <>
      <Modal
        onClose={onClose}
        style={{ width: '100%' }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent className="modal-container">
          <ModalHeader>A few details to get started...</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2}>
              <FormControl isInvalid={aboutError}>
                <FormLabel htmlFor="about">I will never forget...</FormLabel>
                <Input
                  type="text"
                  onChange={e => setAbout(e.target.value)}
                  value={about}
                  placeholder={'Complete the sentence in your own way'}
                />
                <FormErrorMessage>
                  {aboutError && aboutError.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={fileError}>
                <FormLabel>Put your smile here</FormLabel>

                {selectedFile ? (
                  <div className="image-select">
                    <Image borderRadius="full" src={preview} />
                    <div
                      className="image-select-overlay"
                      onClick={() =>
                        document.getElementById('choose_profile').click()
                      }
                    >
                      <Icon as={FaFileUpload} margin={0.5} />
                      <span>Change image</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <Button
                      onClick={() =>
                        document.getElementById('choose_profile').click()
                      }
                    >
                      Upload image
                    </Button>
                    <Text as="em" fontSize={'0.85rem'}>
                      (Max 5mb limit)
                    </Text>
                  </>
                )}

                <Input
                  type="file"
                  onChange={onSelectFile}
                  accept="image/jpeg,image/png"
                  hidden="true"
                  id="choose_profile"
                />

                <FormErrorMessage>
                  {fileError && fileError.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={emailError}>
                <FormLabel htmlFor="email">Personal Email Address</FormLabel>
                <Input
                  type="email"
                  onChange={e => setPersonalEmail(e.target.value)}
                  value={personalEmail}
                  placeholder={'Email'}
                />
                <FormErrorMessage>
                  {emailError && emailError.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={mobileError}>
                <FormLabel htmlFor="name">Mobile Number</FormLabel>
                <Input
                  type={'tel'}
                  placeholder={'Phone Number'}
                  onChange={e => setMobile(e.target.value)}
                  value={mobile}
                  name=" mobile"
                />
                <FormErrorMessage>
                  {mobileError && mobileError.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={submitDetails}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        style={{ width: '100vw', height: '100vh' }}
        className={'home-main-box'}
      >
        <Flex
          position={'relative'}
          flexDirection={'column'}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          padding={'1.5rem'}
          style={{ background: 'var(--blue)', height: '100%', width: '60%' }}
        >
          <Flex direction={'column'} alignItems="center">
            <Image
              src={ARC}
              alt="Alumni Relations Cell"
              className="home-arc-img"
              maxHeight={'25vh'}
              objectFit={'contain'}
            />
            <Text
              fontWeight={'bold'}
              textAlign={'center'}
              style={{ marginTop: '1rem' }}
            >
              presents
            </Text>
          </Flex>
          <Stack>
            <Flex alignItems={'flex-start'}>
              <Image src={M} alt="Melange" className="home-elange-img" />
              <Text
                className="home-elange"
                style={{
                  color: '#F0664A',
                  fontSize: '6rem',
                  marginLeft: '1rem',
                }}
                fontWeight={'extrabold'}
              >
                elange
              </Text>
            </Flex>
            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'2xl'}>
              A yearbook portal
            </Text>
          </Stack>
          <Stack>
            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'2xl'}>
              Made with {'<'}3 by
            </Text>
            <Image
              alt="Devsoc logo"
              style={{ marginTop: '1rem' }}
              src={DEVSOC}
              objectFit={'contain'}
              maxHeight={'17vh'}
            />
          </Stack>
        </Flex>
        <Stack
          style={{
            background: 'var(--red)',
            height: '100%',
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Container display={'grid'}>
            <Text
              textAlign={'center'}
              className="text-home"
              style={{
                marginBottom: '4rem',
                fontSize: '1.25rem',
                color: 'white',
                fontFamily: "Montserrat",
              }}
            >
              Having mastered the sea of courses, it must feel surreal to have
              reached the end of your college life. But it's funny how the
              moment we stepped foot on campus, we were all pre-registered for
              courses in ‘friendship’, ‘love’, ‘fun’, and ‘lite’. And it's about
              time we got a degree for it too!
              <br />
              <br />
              Bound with code and inked with love, the Alumni Relations Cell is
              elated to present the online portal for the yearbook, Melange!
              Relive those crazy outings, laugh at those silly jokes, take
              another trip down smoker’s lane, and speed-run through chapters of
              your college life as you pen down your thoughts for your favourite
              people! Scribble away all those feelings and moments that, bit by
              bit, made your college your home!
            </Text>
            <Center className="glogin">
              <GoogleLogin
                onSuccess={async creds => {
                  try {
                    const res = await post(
                      'https://api.yearbook.bp-gc.in/api/user/oauthlogin',
                      { token: creds.credential }
                    );
                    const { pending } = res.data;
                    setToken(res.data.token);
                    if (!pending) {
                      localStorage.setItem('token', res.data.token);
                      const { email } = JSON.parse(
                        Buffer.from(res.data.token.split('.')[1], 'base64').toString('utf8')
                      );
                      navigate('/user/' + email.split('@')[0]);
                    } else onOpen();
                  } catch (e) {
                    if (e.response.status === 400) {
                      toast({
                        render: () => (
                          <Box color='white' p={3} bg='red.500'>
                            You are not allowed to access the application
                          </Box>),
                        position: 'top',
                        isClosable: true,
                      })
                    } else {
                      console.error(e);
                    }
                  }
                }}
                onError={() => console.log('Error occured')}
                text="continue_with"
                hosted_domain="goa.bits-pilani.ac.in"
                size="large"
              />
            </Center>
          </Container>
        </Stack>
      </Flex>
    </>
  );
};

export default Landing;
