import { Box, Icon, Text, useDisclosure, Badge, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaFileUpload } from 'react-icons/fa';
import ImageModal from './ImageModal';
import mapID from '../utilities/IDmap';
import { Buffer } from 'buffer';

const InfoPanel = ({ user, token }) => {
  const discipline = mapID(user.bitsId);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const { email } = JSON.parse(
    Buffer.from(localStorage.getItem('token').split('.')[1], 'base64').toString('utf8')
  );

  return (
    <Box
      width={'25rem'}
      maxW="26rem"
      rounded={'3xl'}
      background="var(--red)"
      boxShadow={'5px 5px 3px 0 rgba(240, 102, 74, 0.5)'}
    >
      <Stack
        position="relative"
        className={user.email === email ? 'panel-image' : ''}
      >
        <Box
          className={user.email === email ? 'user-image' : ''}
          onClick={() => onModalOpen()}
          background={`linear-gradient(0deg, #000000 0%, rgba(43, 43, 43, 0.9) 18.75%, rgba(98, 98, 98, 0.5) 35.42%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%), url(${process.env.REACT_APP_SERVER_URL}api/user/image/${user.bitsId}?token=${token})`}
          borderTopRadius={'3xl'}
          width={'100%'}
          height={'20rem'}
          backgroundSize="cover"
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-end'}
          backgroundPosition={'center'}
          bgRepeat={'no-repeat'}
          padding={'1rem'}
        >
          <Text
            color="white"
            fontSize={'2xl'}
            fontWeight="bold"
            className="user-text"
          >
            {user.name}
          </Text>
          {user.bitsId !== '2020A3PS2095G' &&
            user.bitsId !== '2019B1A81000G' &&
            user.bitsId !== 'SARC' && (
              <Text color="white" className="user-text">
                {user.bitsId}
              </Text>
            )}
        </Box>

        {email === user.email ? (
          <div
            className="overlay"
            style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <Icon as={FaFileUpload} fill={'white'} height={'100%'} />
            <Text color="white">Change Profile Picture</Text>
          </div>
        ) : (
          <></>
        )}
      </Stack>

      <Box p="6">
        <Box display="flex" alignItems="baseline">
          <Badge borderRadius="full" px="2.5" fontSize={'sm'} colorScheme="red">
            {discipline.length === 1
              ? discipline[0]
              : `${discipline[0]} + ${discipline[1]}`}
          </Badge>
        </Box>
        {user.bitsId !== '2020A3PS2095G' &&
          user.bitsId !== '2019B1A81000G' &&
          user.bitsId !== 'SARC' &&
          user.about && (
            <Box
              mt="3"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              color="black"
            >
              I will never forget {user.about}
            </Box>
          )}

        {/*Image Upload Modal*/}
        {email === user.email ? (
          <ImageModal
            onModalClose={onModalClose}
            isModalOpen={isModalOpen}
            onModalOpen={onModalOpen}
          />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default InfoPanel;
