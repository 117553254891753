import {
  Stat,
  Stack,
  StatLabel,
  StatNumber,
  Tag,
  Avatar,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

const MessageBox = props => {
  const [height, setHeight] = useState(24);
  return (
    <>
      {props.content.length > 80 && (
        <Tooltip label="Expand" placement="bottom">
          <Stack
            background={props.backgroundColor}
            className="mb-main"
            style={{ cursor: 'pointer'}}
            onClick={() => setHeight(height === 24 ? 'auto' : 24)}
          >
            <div className="mb-head">
              <Stat>
                <StatLabel
                  marginTop={'10px'}
                  marginBottom={'10px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'10px'}
                >
                  <Avatar
                    name={props.fromname}
                    src={`${process.env.REACT_APP_SERVER_URL}api/user/image/${
                      props.bitsId
                    }?token=${localStorage.getItem('token')}`}
                  />
                  <div>
                    <Tag
                      backgroundColor={'#ffffff75'}
                      border={'2px solid white'}
                      className="email-tag"
                    >
                      <a href={`/user/${props.from.split('@')[0]}`}>
                        {' '}
                        @{props.from.split('@')[0]}
                      </a>
                    </Tag>
                    <StatLabel>{new Date(props.date).toDateString()}</StatLabel>
                  </div>
                </StatLabel>
                <StatNumber>
                  {props.received ? 'From' : 'To'} {props.fromname}
                </StatNumber>
              </Stat>
            </div>
            <AnimateHeight
              id="message"
              duration={200}
              height={height}
              className="mb-body"
              style={{
                overflowX: 'hidden',
                overflowY: 'clip'
              }}
            >
              {height === 24 ? `${props.content.slice(0,80)} ...` : props.content}
            </AnimateHeight>
            <button
            aria-expanded={height !== 24}
            aria-controls="message"
            onClick={() => setHeight(height === 24 ? 'auto' : 24)}
          >
            {height === 24 ? '▼' : '▲'}
          </button>
          </Stack>
        </Tooltip>
      )}
      {props.content.length <= 100 && (
        <Stack background={props.backgroundColor} className="mb-main">
          <div className="mb-head">
            <Stat>
              <StatLabel
                marginTop={'10px'}
                marginBottom={'10px'}
                display={'flex'}
                alignItems={'center'}
                gap={'10px'}
              >
                <Avatar
                  name={props.fromname}
                  src={`${process.env.REACT_APP_SERVER_URL}api/user/image/${
                    props.bitsId
                  }?token=${localStorage.getItem('token')}`}
                />
                <div>
                  <Tag
                    backgroundColor={'#ffffff75'}
                    border={'2px solid white'}
                    className="email-tag"
                  >
                    <a href={`/user/${props.from.split('@')[0]}`}>
                      {' '}
                      @{props.from.split('@')[0]}
                    </a>
                  </Tag>
                  <StatLabel>{new Date(props.date).toDateString()}</StatLabel>
                </div>
              </StatLabel>
              <StatNumber>
                {props.received ? 'From' : 'To'} {props.fromname}
              </StatNumber>
            </Stat>
          </div>
          <div className="mb-body">{props.content}</div>
        </Stack>
      )}
    </>
  );
};

export default MessageBox;
