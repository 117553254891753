import React from 'react';
import './App.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Landing from './pages/Landing';
import UserPage from './pages/UserPage';
import MessagePage from './pages/MessagePage';
import { ChakraProvider } from '@chakra-ui/react';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
  return (
    <>
      <GoogleOAuthProvider clientId="796324132621-egvs82jqfcajmsonrl8b28dl0g3a7ssn.apps.googleusercontent.com">
        <ChakraProvider textAlign="center">
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/user/:id" element={<UserPage />} />
              <Route path="/messages" element={<MessagePage />} />
            </Routes>
          </Router>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
