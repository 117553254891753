import { Stack, Text, Heading } from '@chakra-ui/react';
import React from 'react';
import MessageBox from './MessageBox';

const UserMessages = ({ user, data, received = true }) => {
  return (
    <Stack className="msg-main" borderWidth={'4px'} borderColor={'red.400'}>
      <Heading
        color={'gray.800'}
        lineHeight={1.1}
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
      >
        Messages {received ? 'Received' : 'Sent'} By{' '}
        <Text as={'span'} color={'red.500'} fontSize={'40px'}>
          {user.name}
        </Text>
      </Heading>
      {data && data.length > 0 ? (
        data.map(msg => (
          <MessageBox
            key={msg._id}
            fromname={msg.name}
            from={received ? msg.senderEmail : msg.receiverEmail}
            content={msg.body}
            date={msg.createdAt}
            received={received}
            backgroundColor={received ? 'var(--grad1)' : 'var(--grad2)'}
            bitsId={msg.bitsId}
          />
        ))
      ) : (
        <Text as="em">No messages to show</Text>
      )}
    </Stack>
  );
};

export default UserMessages;
