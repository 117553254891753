import React, { useState, useEffect, useCallback } from 'react';
import { post } from 'axios';
import {
  useDisclosure,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  Button,
  ModalOverlay,
  Textarea,
  ModalContent,
  Modal,
  InputGroup,
  InputLeftElement,
  Input,
  ModalFooter,
  ModalBody,
  ModalHeader,
  List,
  ListItem,
  Stat,
  StatHelpText,
  StatLabel,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MessagePop() {
  let timer;
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure({ defaultIsOpen: false });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [receiverValid, setReceiverValid] = useState(false);
  const [slist, setSlist] = useState([]);
  const [sendDisable, setSD] = useState(false);

   // eslint-disable-next-line
  const searchList = useCallback(
    debounce(async name => {
      try {
        const token = localStorage.getItem('token');
        let res = await post(
          'https://api.yearbook.bp-gc.in/api/messages/search/',
          {
            query: name,
          },
          {
            headers: { token },
          }
        );
        const { data } = res.data;
        let arr = [];
        let uniqueObject = {};
        for (let i in data) {
          const objTitle = data[i]['bitsId'];
          uniqueObject[objTitle] = data[i];
        }
        for (let i in uniqueObject) {
          arr.push(uniqueObject[i]);
        }
        setSlist(arr);
      } catch (e) {
        console.log(e);
      }
    }),
    []
  );

  const clearClose = () => {
    setEmail('');
    setName('');
    setMessage('');
    setReceiverValid(false);
    onModalClose();
  };

  const handleOpen = () => {
    clearTimeout(timer);
    onModalOpen();
  };

  async function sendMessage() {
    try {
      const token = localStorage.getItem('token');
      await post(
        'https://api.yearbook.bp-gc.in/api/messages/sendmessage/',
        {
          messageBody: message,
          receiverEmail: email,
        },
        {
          headers: { token },
        }
      );
      setEmail('');
      setName('');
      setMessage('');
      setReceiverValid(false);
      setSD(true);
      onAlertOpen();

      timer = setTimeout(() => {
        onAlertClose();
        onModalClose();
        setSD(false);
      }, 3000);
      // ok=1 if message sent successfully
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (!name) setSlist([]);
    else searchList(name);
     // eslint-disable-next-line
  }, [name]);

  return (
    <>
      <Button
        variant={'solid'}
        background={'#ED7156'}
        size={'lg'}
        rounded={'xl'}
        mr={4}
        color={'black'}
        onClick={handleOpen}
        leftIcon={<AddIcon />}
      >
        Send a message
      </Button>
      <br />
      <Modal
        isCentered
        onClose={onModalClose}
        isOpen={isModalOpen}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={'3xl'}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent
          borderWidth={'4px'}
          borderColor={'red.400'}
          borderRadius={20}
          margin={0}
        >
          <ModalHeader>Send a Message!</ModalHeader>
          <ModalBody>
            {isAlertOpen ? (
              <Alert status="success">
                <Flex justifyContent={'space-around'}>
                  <div style={{ display: 'flex' }}>
                    <AlertIcon />
                    <Box style={{ width: '100%' }}>
                      <AlertTitle>Success!</AlertTitle>
                      <AlertDescription>
                        Your message has been successfully submitted!
                      </AlertDescription>
                    </Box>
                  </div>
                </Flex>
              </Alert>
            ) : (
              <Box display={'flex'} flexDirection={'column'}>
                <Box overflowY={'visible'} height={'2rem'}>
                  {receiverValid ? (
                    <Tag size="lg" colorScheme="pink">
                      <TagLabel>{`${name} <${email}>`}</TagLabel>
                      <TagCloseButton
                        onClick={e => {
                          setReceiverValid(false);
                        }}
                      />
                    </Tag>
                  ) : (
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <FontAwesomeIcon color="grey" icon={faSearch} />
                        }
                      />
                      <Input
                        type="text"
                        value={name}
                        onChange={e => {
                          setName(e.target.value);
                          setReceiverValid(false);
                        }}
                        placeholder="Name or Email"
                        borderWidth={'3px'}
                        borderColor={'red.400'}
                        borderRadius={20}
                      />
                    </InputGroup>
                  )}
                  {!receiverValid && slist.length > 0 && (
                    <List
                      maxHeight={'15rem'}
                      overflowY={'scroll'}
                      paddingLeft={'2.5rem'}
                      boxShadow={'lg'}
                      backgroundColor={'whitesmoke'}
                    >
                      {slist.map(person => (
                        <ListItem
                          key={person.bitsId}
                          onClick={() => {
                            setName(person.name);
                            setEmail(person.email);
                            setSlist([]);
                            setReceiverValid(true);
                          }}
                          cursor="pointer"
                        >
                          <Stat>
                            <StatLabel>{person.name}</StatLabel>
                            <StatHelpText>{person.email}</StatHelpText>
                          </Stat>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
                <Textarea
                  height={'md'}
                  style={{ marginTop: '2rem' }}
                  isRequired
                  size={'md'}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Your message..."
                  borderWidth={'3px'}
                  borderColor={'red.400'}
                  borderRadius={20}
                  zIndex={receiverValid ? 1 : -1}
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={clearClose}>
              Close
            </Button>
            <Button
              colorScheme="red"
              onClick={sendMessage}
              disabled={sendDisable}
            >
              Send Message
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MessagePop;
