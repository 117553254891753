import React from 'react';
import {
  Box,
  Badge,
  Flex,
  Link,
  IconButton,
  ButtonGroup,
  Avatar,
  Text,
} from '@chakra-ui/react';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';

export default function Card({ imageUrl, role, name, arc, github, linkedin }) {
  return (
    <Box borderWidth="3px" borderRadius="lg" padding={'1rem'}>
      <Box display={'flex'} alignItems={'center'}>
        <Avatar
          src={imageUrl}
          name={name}
          alt={`${name}'s Photo`}
          size={'2xl'}
          margin={'0.5rem'}
        />
        <Box
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          alignItems={'center'}
        >
          <Text margin={1} fontSize={'lg'}>
            {name}
          </Text>
          <Badge colorScheme={'teal'} margin={1}>
            {role}
          </Badge>
          <Flex justifyContent={'center'}>
            <Flex justifyContent={'space-around'}>
              <ButtonGroup>
                <Link
                  target={'_blank'}
                  href={!arc ? github : `mailto:${github}`}
                >
                  <IconButton
                    icon={!arc ? <FaGithub /> : <FaEnvelope />}
                    variant={'ghost'}
                  />
                </Link>
                <Link target={'_blank'} href={linkedin}>
                  <IconButton icon={<FaLinkedin />} variant={'ghost'} />
                </Link>
              </ButtonGroup>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
