import React from 'react';
import { Box, Link, Text, Image, IconButton } from '@chakra-ui/react';
import ARC from '../assets/img/ARC.png';
import M from '../assets/img/Union.png';
import DEVSOC from '../assets/img/DEVSOC.png';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { BsHeartFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';

function Footer() {
  return (
    <Box
      bg={
        'linear-gradient(90deg, rgba(115, 193, 228, 0.5) 0%, rgba(242, 235, 61, 0.5) 6%, rgba(242, 235, 61, 0.5) 94%, rgba(240, 102, 74, 0.5) 100%);'
      }
      mt={20}
      color={'black'}
      padding={'2rem 2.5rem'}
      display={'flex'}
      className="footer-custom"
      justifyContent={'space-between'}
      sx={{
        borderTop: '5px solid var(--red)',
        borderRadius: '20px 20px 0 0',
        width: '100%',
      }}
    >
      <Box className="footer-arc">
        <div>
          <Image src={ARC} alt="Alumni Relations Cell" height={'3.5rem'} />
          <Text
            fontSize={'1.25rem'}
            fontWeight={700}
            fontFamily={'"Roboto"'}
            mt={'1rem'}
            mb={'0.5rem'}
            fontStyle={'normal'}
          >
            Contact Us
          </Text>
          <Box>
            <a href="https://www.instagram.com/bitsgoa_arc/">
              <IconButton
                icon={<AiFillInstagram />}
                variant={'link'}
                height={'1.5rem'}
              />
            </a>
            <a href="https://www.linkedin.com/company/alumni-relations-cell-bits-pilani-k-k-birla-goa-campus/">
              <IconButton
                icon={<FaLinkedinIn />}
                variant={'link'}
                height={'1.5rem'}
              />
            </a>
            <a href="mailto:sarc@goa.bits-pilani.ac.in">
              <IconButton
                icon={<MdEmail />}
                variant={'link'}
                height={'1.5rem'}
              />
            </a>
          </Box>
        </div>
      </Box>
      <Box className="footer-div-custom footer-devsoc">
        <div>
          <Image src={DEVSOC} alt="DevSoc" height={'6rem'} />
          <Box>
            <a href="https://www.instagram.com/devsocbitsgoa/">
              <IconButton
                icon={<AiFillInstagram />}
                variant={'link'}
                height={'1.5rem'}
                my={'1rem'}
              />
            </a>
            <a href="https://github.com/Devsoc-BPGC">
              <IconButton
                icon={<FaGithub />}
                variant={'link'}
                height={'1.5rem'}
                my={'1rem'}
              />
            </a>
            <a href="https://www.linkedin.com/company/devsoc-bpgc/mycompany/">
              <IconButton
                icon={<FaLinkedinIn />}
                variant={'link'}
                height={'1.5rem'}
                my={'1rem'}
              />
            </a>
          </Box>
        </div>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        className="footer-melange"
      >
        <Box display={'flex'} alignItems={'center'} justifySelf={'center'}>
          <Image src={M} height={'4rem'} />
          <Text
            fontSize={'4rem'}
            fontWeight={'extrabold'}
            marginLeft={'0.5rem'}
            color={'#F0664A'}
          >
            elange
          </Text>
        </Box>
        <Box fontSize={'1.25rem'}>
          Made with{' '}
          <BsHeartFill
            fill="red"
            style={{
              display: 'inline',
            }}
          />{' '}
          by{' '}
          <Link fontWeight="bold" href="https://devsoc.club/">
            DevSoc
          </Link>{' '}
          in collaboration with{' '}
          <Link fontWeight="bold" href="https://alumniaffairs.bits-goa.ac.in/">
            ARC
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
