import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  SimpleGrid,
  Heading,
  Box,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import pi from '../assets/img/frontend/pi.jpg';
import krishanu from '../assets/img/frontend/krishanu.jpg';
import nerdy from '../assets/img/backend/ananth.jpg';
import bansal from '../assets/img/coordis/bansal.jpg';
import ducks from '../assets/img/coordis/ducks.jpg';
import risky from '../assets/img/coordis/risky.jpg';
import uv from '../assets/img/coordis/uv.jpg';
import anish from '../assets/img/coordis/anish.jpeg';
import aman from '../assets/img/coordis/aman.jpeg';
import ashlesha from '../assets/img/coordis/ashlesha.jpeg';
import suprada from '../assets/img/coordis/suprada.jpeg';
import nipun from '../assets/img/coordis/nipun.jpeg';
import hrithik from '../assets/img/coordis/hrithik.jpeg';
import Card from './Card';

function Credits({ onCreditsClose, isCreditsOpen }) {
  const cordis = [
    {
      imageUrl: bansal,
      name: 'Arihant Bansal',
      role: 'Chief Coordinator',
      github: 'https://github.com/arihantbansal',
      linkedin: 'https://www.linkedin.com/in/arihantbansal/',
    },
    {
      imageUrl: anish,
      name: 'Anish Sreenivas',
      role: 'Sub Coordinator',
      github: 'https://github.com/anishsreenivas82',
      linkedin: 'https://www.linkedin.com/in/anish-sreenivas-6b59361b9/',
    },
    {
      imageUrl: risky,
      name: 'Hrishikesh Patil',
      role: 'Web Dev Vertical Lead',
      github: 'https://github.com/riskycase',
      linkedin: 'https://www.linkedin.com/in/riskycase',
    },
    {
      imageUrl: uv,
      name: 'Yuvraj Singh Bhadauria',
      role: 'App Dev Vertical Lead',
      github: 'https://github.com/UvrajSB',
      linkedin: 'https://www.linkedin.com/in/yuvraj-bhadauria-927a1b1b9/',
    },
    {
      imageUrl: aman,
      name: 'Aman Rahman',
      role: 'Game Dev Vertical Lead',
      github: 'https://github.com/Virtucious',
      linkedin: 'https://www.linkedin.com/in/aman-rahman-7917bb204/',
    },
    {
      imageUrl: ducks,
      name: 'Daksh Goel',
      role: 'Design Vertical Lead',
      github: 'https://github.com/dakshisdaksh',
      linkedin: 'https://www.linkedin.com/in/dakshisdaksh/',
    },
  ];
  const frontend = [
    {
      imageUrl: krishanu,
      name: 'Krishanu Shah',
      role: 'Frontend Developer',
      github: 'https://github.com/krishanu-xc',
      linkedin: 'https://www.linkedin.com/in/krishanu-xc-shah',
    },
    {
      imageUrl: pi,
      name: 'Piyush Mohite',
      role: 'Frontend Developer',
      github: 'https://github.com/git-pi-e',
      linkedin: 'https://www.linkedin.com/in/piyush-mohite-2b66421ba/',
    },
    {
      imageUrl: nerdy,
      name: 'Ananth Raghav',
      role: 'Backend Developer',
      github: 'https://github.com/ananth243',
      linkedin: 'https://www.linkedin.com/in/ananth-raghav-2151a9200',
    },
  ];
  const arc = [
    {
      imageUrl: ashlesha,
      name: 'Ashlesha Jagdale',
      role: 'Secretary',
      github: 'f20190229@goa.bits-pilani.ac.in',
      linkedin: 'https://www.linkedin.com/mwlite/in/ashlesha-jagdale-2aa21a193',
    },
    {
      imageUrl: nipun,
      name: 'Nipun Gupta',
      role: 'Executive Coordinator',
      github: ' f20191000@goa.bits-pilani.ac.in',
      linkedin: 'https://www.linkedin.com/in/nipun-gupta-gn18/',
    },
    {
      imageUrl: hrithik,
      name: 'Hrithik Khanna',
      role: 'Finance and Logistics Head',
      github: 'f20190840@goa.bits-pilani.ac.in',
      linkedin: 'https://in.linkedin.com/in/hrithik-khanna-843880192',
    },
    {
      imageUrl: suprada,
      name: 'Suprada Bhat',
      role: 'PR Head',
      github: ' f20190885@goa.bits-pilani.ac.in',
      linkedin: 'https://www.linkedin.com/in/suprada-bhat-246bb2192',
    },
  ];
  function cards(people, arc = false) {
    return (
      <SimpleGrid columns={[2]} gap={'1rem'}>
        {people.map((person, index) => (
          <Card
            key={index}
            arc={arc}
            name={person.name}
            imageUrl={person.imageUrl}
            role={person.role}
            github={person.github}
            linkedin={person.linkedin}
          ></Card>
        ))}
      </SimpleGrid>
    );
  }
  return (
    <Modal onClose={onCreditsClose} size={'full'} isOpen={isCreditsOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody width={'100%'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            margin={2}
            width={'100%'}
          >
            <Heading margin={3}>Meet the team</Heading>
            <Tabs
              variant={'solid-rounded'}
              colorScheme={'pink'}
              align={'center'}
              width={'100%'}
            >
              <TabList>
                <Tab>Coordinators</Tab>
                <Tab>Developers</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text textAlign={'center'} fontSize={'2xl'}>
                    Alumni Relations Cell
                  </Text>
                  <br />
                  {cards(arc, true)}
                  <br />
                  <Text textAlign={'center'} fontSize={'2xl'}>
                    Developer's Society of BITS Goa
                  </Text>
                  <br />
                  {cards(cordis)}
                </TabPanel>
                <TabPanel>
                  <Text textAlign={'center'} fontSize={'2xl'}>
                    Developer's Society of BITS Goa
                  </Text>
                  <br />
                  {cards(frontend)}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Credits;
