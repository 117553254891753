/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  Stat,
  StatLabel,
  List,
  ListItem,
  StatHelpText,
  MenuList,
  MenuItem,
  Text,
  Stack,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { post } from 'axios';
import { useNavigate } from 'react-router-dom';
import Credits from './Credits';
import ARC from '../assets/img/ARC.png';
import M from '../assets/img/Union.png';
import { Buffer } from 'buffer';

const Navbar = () => {
  const { isOpen } = useDisclosure();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [name, setName] = useState('');
  const [receiverValid, setReceiverValid] = useState(false);
  const [slist, setSlist] = useState([]);
  const {
    isOpen: isCreditsOpen,
    onClose: onCreditsClose,
    onOpen: onCreditsOpen,
  } = useDisclosure();
  const token = localStorage.getItem('token');
  const searchList = useCallback(
    debounce(async name => {
      try {
        const token = localStorage.getItem('token');
        let res = await post(
          'https://api.yearbook.bp-gc.in/api/messages/search/',
          {
            query: name,
          },
          {
            headers: { token },
          }
        );
        const { data } = res.data;
        let arr = [];
        let uniqueObject = {};
        for (let i in data) {
          const objTitle = data[i]['bitsId'];
          uniqueObject[objTitle] = data[i];
        }
        for (let i in uniqueObject) {
          arr.push(uniqueObject[i]);
        }
        setSlist(arr);
      } catch (e) {
        console.log(e);
      }
    }),
    []
  );

  useEffect(() => {
    if (!token) navigate('/');
    else {
      const arr = localStorage.getItem('token').split('.');
      if (arr.length !== 3) navigate('/');
      setUser(JSON.parse(Buffer.from(arr[1], 'base64').toString('utf8')));
    }
  }, []);

  useEffect(() => {
    if (!name) setSlist([]);
    else searchList(name);
  }, [name]);
  return (
    user && (
      <>
        <Box
          bg={
            'linear-gradient(90deg, rgba(115, 193, 228, 0.5) 0%, rgba(242, 235, 61, 0.5) 6%, rgba(242, 235, 61, 0.5) 94%, rgba(240, 102, 74, 0.5) 100%);'
          }
          px={'2.5rem'}
          py={'0.75rem'}
          //   display={'flex'}
          className="navbar-header"
          //   justifyContent={'space-between'}
          sx={{
            borderBottom: '5px solid var(--red)',
            borderRadius: '0 0 20px 20px',
          }}
        >
          <Image
            src={ARC}
            alt="Alumni Relations Cell"
            className="image-onlow"
            height={'3.5rem'}
          />
          <a href={'/user/' + user.email.split('@')[0]} style={{
            width: 'min-content'
          }}>
          <Box display={'flex'} alignItems={'center'} justifySelf={'center'}>
            <Image src={M} height={'3.5rem'} className="image-onlow" />
            <Text
              fontSize={'3rem'}
              fontWeight={'extrabold'}
              marginLeft={'0.5rem'}
              color={'#F0664A'}
            >
              elange
            </Text>
          </Box>
          </a>
          <Flex h={16} alignItems={'center'} justifyContent={'flex-end'}>
            <Flex alignItems={'center'} justifyContent={'space-around'}>
              <Stack
                border={'1px solid white'}
                borderRadius={'5px'}
                id="custom-input"
              >
                <InputGroup
                  background={'white'}
                  rounded={'2xl'}
                  style={{ marginRight: '1rem' }}
                >
                  <Input
                    placeholder="Search for a user"
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                      setReceiverValid(false);
                    }}
                  />
                  <InputRightElement
                    children={<FontAwesomeIcon icon={faSearch} />}
                  />
                </InputGroup>
                {!receiverValid && slist.length > 0 && (
                  <List
                    maxHeight={'44'}
                    overflowY={'scroll'}
                    spacing={0}
                    position={'absolute'}
                    backgroundColor={'white'}
                    padding={2}
                    width={document.getElementById('custom-input').width}
                    top={'60px'}
                    zIndex={'999'}
                    borderRadius={'10px'}
                    border={'1px solid var(--red)'}
                  >
                    {slist.map(person => (
                      <ListItem
                        key={person.bitsId}
                        backgroundColor={'white'}
                        onClick={() => {
                          setSlist([]);
                          setReceiverValid(true);
                          setName(person.name);
                          //   navigate('/user/' + person.email.split('@')[0]);
                          window.location.href =
                            '/user/' + person.email.split('@')[0];
                        }}
                        cursor={'pointer'}
                      >
                        <Stat>
                          <StatLabel>{person.name}</StatLabel>
                          <StatHelpText>{person.email}</StatHelpText>
                        </Stat>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Stack>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                  sx={{ marginLeft: 5 }}
                >
                  {user.ext && (
                    <Avatar
                      size={'md'}
                      backgroundColor={'transparent'}
                      src={`${process.env.REACT_APP_SERVER_URL}api/user/image/${user.bitsId}?token=${token}`}
                    />
                  )}
                  {!user.ext && <Avatar name={user.name} size={'sm'} />}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      navigate('/messages');
                    }}
                  >
                    Sent messages
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      //   navigate('/user/' + user.email.split('@')[0]);
                      window.location.href =
                        '/user/' + user.email.split('@')[0];
                      //   window.location.reload();
                    }}
                  >
                    Received messages
                  </MenuItem>

                  <MenuItem onClick={onCreditsOpen}>Credits</MenuItem>

                  <Credits
                    isCreditsOpen={isCreditsOpen}
                    onCreditsClose={onCreditsClose}
                  />
                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem('token');
                      window.location.href = '/';
                    }}
                  >
                    Sign out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>

          {isOpen ? <Box pb={4} display={{ md: 'none' }}></Box> : null}
        </Box>
      </>
    )
  );
};

export default Navbar;
