export default function mapID(bitsId) {
  if (bitsId === '2020A3PS2095G') return ['DevSoc Moderator'];
  else if (bitsId === '2019B1A81000G') return ['ARC Moderator'];
  else if (bitsId === 'SARC') return ['ARC Admin'];
  const map = {
    A8: 'Instrumentation',
    A3: 'Electrical',
    A4: 'Mechanical',
    AA: 'Communication',
    A7: 'Computer Science',
    A1: 'Chemical',
    B4: 'Mathematics',
    B5: 'Physics',
    B2: 'Chemistry',
    B1: 'Biology',
    B3: 'Economics',
    H: 'Masters',
  };
  const discipline = [];
  Object.keys(map).forEach(key => {
    if (bitsId.includes(key)) {
      discipline.push(map[key]);
    }
  });
  return discipline;
}
