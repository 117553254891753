/* eslint-disable react-hooks/exhaustive-deps */
import { get } from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InfoPanel from '../components/UserInfoPanel';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import UserMessages from '../components/UserMStream';
import MessagePop from '../components/Modal';
const UserPage = props => {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const [data, setData] = React.useState(null);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      if (!token) navigate('/');
      try {
        const { data } = await get(
          `https://api.yearbook.bp-gc.in/api/messages/receivedmessages?id=${id}`,
          { headers: { token } }
        );
        setData(data?.data?.data);
        setUser(data?.data?.user);
      } catch (error) {
        if (
          error?.response?.data?.error === 'jwt malformed' ||
          error?.response?.data?.error === 'invalid signature' ||
          error?.response?.data?.error === 'jwt expired' ||
          error?.response?.data?.error === 'invalid token'
        ) {
          localStorage.removeItem('token');
          navigate('/');
        } else console.error(error);
      }
    }
    fetchData();
  }, []);
  return (
    user &&
    data && (
      <>
        <Navbar />
        <div className="user-main">
          <div className="temp-contain">
            <div className="custom-send-message">
              <MessagePop />
            </div>
            <InfoPanel user={user} token={token} />
          </div>
          <UserMessages user={user} data={data} />
        </div>
        <Footer />
      </>
    )
  );
};

export default UserPage;
