import { get } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MessagePop from '../components/Modal';
import InfoPanel from '../components/UserInfoPanel';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import UserMessages from '../components/UserMStream';
import { Buffer } from 'buffer';

function MessagePage() {
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) navigate('/');
    async function fetchData() {
      try {
        const res = await get(
          'https://api.yearbook.bp-gc.in/api/messages/sentmessages',
          { headers: { token } }
        );
        setToken(token);
        setData(res.data.data);
        setUser(JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8')));
      } catch (error) {
        if (
          error.response.data.error === 'jwt malformed' ||
          error.response.data.error === 'invalid signature' ||
          error.response.data.error === 'jwt expired' ||
          error.response.data.error === 'invalid token'
        ) {
          localStorage.removeItem('token');
          navigate('/');
        } else console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    user &&
    data && (
      <>
        <Navbar />
        <div className="user-main">
          <div className="temp-contain">
            <div className="custom-send-message">
              <MessagePop />
            </div>
            <InfoPanel user={user} token={token} />
          </div>
          <UserMessages user={user} data={data} received={false} />
        </div>
        <Footer />
      </>
    )
  );
}

export default MessagePage;
